<template>
  <div class="tariffs" id="tariffs">
    <div class="tariffs__container _container">
      <h1 class="tariffs__title main-title">{{ $t("tariffs") }}</h1>
      <div class="tariffs__body">
        <!-- {{ tariffs }} -->
        <TabItem
          :tabItems="[
            $t('tabs.aquapark'),
            $t('tabs.spa'),
            $t('tabs.birthday'),
            $t('tabs.club'),
          ]"
          :color="'#ffffff'"
          :selected="selected"
          @setTab="selectTab"
        >
        </TabItem>
        <div class="tariffs__content">
          <TabBlock :isSelected="selected === $t('tabs.aquapark')">
            <div class="tariffs__items">
              <div
                v-for="(tariff, index) in tariffs.aquapark"
                class="tariff-item"
                :key="tariff.id"
                v-show="tariff.is_active == '1'"
              >

                <div class="tariff-item__content">
                  <div class="tariff-item__top" ref="tariff-top">
                    <div class="tariff-item__info" >
                      <div class="tariff-item__title" @click="openCard = (openCard === index ? -1 : index)">
                        <p>Тариф</p>
                        <img src="@/assets/icons/Icon.svg" style="cursor: pointer !important;"
                          data-show="true"/>
                      </div>
                      <div class="tariff-item__name" @click="openCard = (openCard === index ? -1 : index)">{{ tariff.ticket_title[$lang] }}
                        <img src="@/assets/icons/Icon.svg" style="cursor: pointer !important;"
                          data-show="true"/></div>
                          <p class="shortDesc">{{ tariff.short_description[$lang] }}</p>
                      <div class="tariff-item__adult">
                        <div class="tariff-item__adult__top">
                          <p class="name">{{ tariff.adult_ticket[$lang] }}</p>
                          <p class="price">{{ tariff.adult_actual_price }} тг.</p>
                        </div>
                        <div class="tariff-item__adult__top" style="align-items: end">
                          <p class="name1">{{ $t("priceWithout") }}</p>
                          <div style="border-bottom: 1px dotted; width: 100%;"></div>
                          <p class="price1">{{ tariff.adult_old_price }} тг.</p>
                        </div>
                        <template style="display: none;">
                          {{ tariff.counter = tariff.counter || 0 + activeCart[tariff.id]?.quantStat || 0 }}
                          {{ tariff.counterChild = tariff.counterChild || 0 + activeCart[tariff.id]?.quantChild || 0 }}
                        </template>
                        <div class="tariff-item__adult__count">
                          <div class="tariff-item__adult__count__left" :class="{disableCounter: activeCart[tariff.id]?.is_adult}">
                            <img src="@/assets/icons/minusCount.svg"  @click="updateCounter(tariff, 'minus')" style="cursor: pointer; user-select: all; pointer-events: all"/>
                            <p>{{tariff.counter ? tariff.counter : 0}}</p>
                            <img src="@/assets/icons/plusCount.svg" @click="updateCounter(tariff, 'plus')" style="cursor: pointer; user-select: all; pointer-events: all"/>
                          </div>
                          <p v-if="!activeCart[tariff.id]?.is_adult" @click.stop="addToCart(tariff, 'adult')">{{ $t("basket.addCard") }}</p>
                          <p v-else @click.stop="addToCart(tariff, 'adult')">{{ $t("deletCart") }}</p>
                        </div>
                      </div>
                      <div class="tariff-item__adult" v-if="tariff.child_actual_price">
                        <div class="tariff-item__adult__top">
                          <p class="name">{{ tariff.child_ticket[$lang] }}</p>
                          <p class="price">{{ tariff.child_actual_price }} тг.</p>
                        </div>
                        <div class="tariff-item__adult__top" style="align-items: end">
                          <p class="name1">{{ $t("priceWithout") }}</p>
                          <div style="border-bottom: 1px dotted; width: 100%;"></div>
                          <p class="price1">{{ tariff.child_old_price }} тг.</p>
                        </div>
                        <div class="tariff-item__adult__count">
                          <div class="tariff-item__adult__count__left" :class="{disableCounter: activeCart[tariff.id]?.is_child}">
                            <img src="@/assets/icons/minusCount.svg"  @click="updateCounterChild(tariff, 'minus')" style="cursor: pointer; user-select: all; pointer-events: all"/>
                            <p>{{tariff.counterChild ? tariff.counterChild : 0}}</p>
                            <img src="@/assets/icons/plusCount.svg" @click="updateCounterChild(tariff, 'plus')" style="cursor: pointer; user-select: all; pointer-events: all"/>
                          </div>
                          <p v-if="!activeCart[tariff.id]?.is_child" @click.stop="addToCart(tariff, 'child')">{{ $t("basket.addCard") }}</p>
                          <p v-else @click.stop="addToCart(tariff, 'child')">{{ $t("deletCart") }}</p>
                        </div>
                      </div>
                      <p v-if="tariff.action == 1" style="margin-top: 10px; color: #ED5336; text-align: center; font-weight: 700; font-size: 17px;">{{$t("bonusLoyality1")}}<span style="color: #44C45F;">{{$t("bonusLoyality2")}}</span></p>
                    </div>
                    <transition name="desc">
                    <div
                      v-if="index === openCard"
                      class="tariff-item__description"
                      v-html="tariff.large_description[$lang]"
                    ></div>
                  </transition>
                  </div>
                </div>
              </div>
            </div>
          </TabBlock>
          <TabBlock :isSelected="selected === $t('tabs.spa')">
            <div class="tariffs__items">
              <div
                v-for="(tariff, index) in tariffs.spa"
                class="tariff-item"
                :key="tariff.id"
                v-show="tariff.is_active == '1'"
              >
              <div class="tariff-item__content">
                  <div class="tariff-item__top" ref="tariff-top">
                    <div class="tariff-item__info" >
                      <div class="tariff-item__title" @click="openCard = (openCard === index ? -1 : index)">
                        <p>Тариф</p>
                        <img src="@/assets/icons/Icon.svg" style="cursor: pointer !important;"
                          data-show="true"/>
                      </div>
                      <div class="tariff-item__name" @click="openCard = (openCard === index ? -1 : index)">{{ tariff.ticket_title[$lang] }}
                        <img src="@/assets/icons/Icon.svg" style="cursor: pointer !important;"
                          data-show="true"/></div>
                          <p class="shortDesc">{{ tariff.short_description[$lang] }}</p>
                      <div class="tariff-item__adult">
                        <div class="tariff-item__adult__top">
                          <p class="name">{{ tariff.adult_ticket[$lang] }}</p>
                          <p class="price">{{ tariff.adult_actual_price }} тг.</p>
                        </div>
                        <div class="tariff-item__adult__top" style="align-items: end">
                          <p class="name1">{{ $t("priceWithout") }}</p>
                          <div style="border-bottom: 1px dotted; width: 100%;"></div>
                          <p class="price1">{{ tariff.adult_old_price }} тг.</p>
                        </div>
                        <template style="display: none;">
                          {{ tariff.counter = tariff.counter || 0 + activeCart[tariff.id]?.quantStat || 0 }}
                          {{ tariff.counterChild = tariff.counterChild || 0 + activeCart[tariff.id]?.quantChild || 0 }}
                        </template>
                        <div class="tariff-item__adult__count">
                          <div class="tariff-item__adult__count__left" :class="{disableCounter: activeCart[tariff.id]?.is_adult}">
                            <img src="@/assets/icons/minusCount.svg"  @click="updateCounter(tariff, 'minus')" style="cursor: pointer; user-select: all; pointer-events: all"/>
                            <p>{{tariff.counter ? tariff.counter : 0}}</p>
                            <img src="@/assets/icons/plusCount.svg" @click="updateCounter(tariff, 'plus')" style="cursor: pointer; user-select: all; pointer-events: all"/>
                          </div>
                          <p v-if="!activeCart[tariff.id]?.is_adult" @click.stop="addToCart(tariff, 'adult')">{{ $t("basket.addCard") }}</p>
                          <p v-else @click.stop="addToCart(tariff, 'adult')">{{ $t("deletCart") }}</p>
                        </div>
                      </div>
                      <div class="tariff-item__adult" v-if="tariff.child_actual_price">
                        <div class="tariff-item__adult__top">
                          <p class="name">{{ tariff.child_ticket[$lang] }}</p>
                          <p class="price">{{ tariff.child_actual_price }} тг.</p>
                        </div>
                        <div class="tariff-item__adult__top" style="align-items: end">
                          <p class="name1">{{ $t("priceWithout") }}</p>
                          <div style="border-bottom: 1px dotted; width: 100%;"></div>
                          <p class="price1">{{ tariff.child_old_price }} тг.</p>
                        </div>
                        <div class="tariff-item__adult__count">
                          <div class="tariff-item__adult__count__left" :class="{disableCounter: activeCart[tariff.id]?.is_child}">
                            <img src="@/assets/icons/minusCount.svg"  @click="updateCounterChild(tariff, 'minus')" style="cursor: pointer; user-select: all; pointer-events: all"/>
                            <p>{{tariff.counterChild ? tariff.counterChild : 0}}</p>
                            <img src="@/assets/icons/plusCount.svg" @click="updateCounterChild(tariff, 'plus')" style="cursor: pointer; user-select: all; pointer-events: all"/>
                          </div>
                          <p v-if="!activeCart[tariff.id]?.is_child" @click.stop="addToCart(tariff, 'child')">{{ $t("basket.addCard") }}</p>
                          <p v-else @click.stop="addToCart(tariff, 'child')">{{ $t("deletCart") }}</p>
                        </div>
                        <p v-if="tariff.action == 1" style="margin-top: 10px; color: #ED5336; text-align: center; font-weight: 700; font-size: 17px;">{{$t("bonusLoyality1")}}<span style="color: #44C45F;">{{$t("bonusLoyality2")}}</span></p>
                      </div>
                    </div>
                    <transition name="desc">
                    <div
                      v-if="index === openCard"
                      class="tariff-item__description"
                      v-html="tariff.large_description[$lang]"
                    ></div>
                  </transition>
                  </div>
                </div>
              </div>
            </div>
          </TabBlock>
          <TabBlock :isSelected="selected === $t('tabs.birthday')">
            <div class="tariffs__items">
              <div
                v-for="(tariff, index) in tariffs.birthday"
                class="tariff-item"
                :key="tariff.id"
                v-show="tariff.is_active == '1'"
              >
              <div class="tariff-item__content">
                  <div class="tariff-item__top" ref="tariff-top">
                    <div class="tariff-item__info" >
                      <div class="tariff-item__title" @click="openCard = (openCard === index ? -1 : index)">
                        <p>Тариф</p>
                        <img src="@/assets/icons/Icon.svg" style="cursor: pointer !important;"
                          data-show="true"/>
                      </div>
                      <div class="tariff-item__name" @click="openCard = (openCard === index ? -1 : index)">{{ tariff.ticket_title[$lang] }}
                        <img src="@/assets/icons/Icon.svg" style="cursor: pointer !important;"
                          data-show="true"/></div>
                          <p class="shortDesc">{{ tariff.short_description[$lang] }}</p>
                      <div class="tariff-item__adult">
                        <div class="tariff-item__adult__top">
                          <p class="name">{{ tariff.adult_ticket[$lang] }}</p>
                          <p class="price">{{ tariff.adult_actual_price }} тг.</p>
                        </div>
                        <div class="tariff-item__adult__top" style="align-items: end">
                          <p class="name1">{{ $t("priceWithout") }}</p>
                          <div style="border-bottom: 1px dotted; width: 100%;"></div>
                          <p class="price1">{{ tariff.adult_old_price }} тг.</p>
                        </div>
                        <template style="display: none;">
                          {{ tariff.counter = tariff.counter || 0 + activeCart[tariff.id]?.quantStat || 0 }}
                          {{ tariff.counterChild = tariff.counterChild || 0 + activeCart[tariff.id]?.quantChild || 0 }}
                        </template>
                        <div class="tariff-item__adult__count">
                          <div class="tariff-item__adult__count__left" :class="{disableCounter: activeCart[tariff.id]?.is_adult}">
                            <!-- <img src="@/assets/icons/minusCount.svg"  @click="updateCounter(tariff, 'minus')" style="cursor: pointer; user-select: all; pointer-events: all"/>
                            <p>{{tariff.counter ? tariff.counter : 0}}</p>
                            <img src="@/assets/icons/plusCount.svg" @click="updateCounter(tariff, 'plus')" style="cursor: pointer; user-select: all; pointer-events: all"/> -->
                          </div>
                          <!-- <p v-if="!activeCart[tariff.id]?.is_adult" @click.stop="addToCart(tariff, 'adult')">{{ $t("basket.addCard") }}</p>
                          <p v-else @click.stop="addToCart(tariff, 'adult')">{{ $t("deletCart") }}</p> -->
                          <a href="https://dnirozhdeniya.hawaii.kz/">{{ $t("newRequest") }}</a>
                        </div>
                      </div>
                      <div class="tariff-item__adult" v-if="tariff.child_actual_price">
                        <div class="tariff-item__adult__top">
                          <p class="name">{{ tariff.child_ticket[$lang] }}</p>
                          <p class="price">{{ tariff.child_actual_price }} тг.</p>
                        </div>
                        <div class="tariff-item__adult__top" style="align-items: end">
                          <p class="name1">{{ $t("priceWithout") }}</p>
                          <div style="border-bottom: 1px dotted; width: 100%;"></div>
                          <p class="price1">{{ tariff.child_old_price }} тг.</p>
                        </div>
                        <div class="tariff-item__adult__count">
                          <div class="tariff-item__adult__count__left" :class="{disableCounter: activeCart[tariff.id]?.is_child}">
                            <img src="@/assets/icons/minusCount.svg"  @click="updateCounterChild(tariff, 'minus')" style="cursor: pointer; user-select: all; pointer-events: all"/>
                            <p>{{tariff.counterChild ? tariff.counterChild : 0}}</p>
                            <img src="@/assets/icons/plusCount.svg" @click="updateCounterChild(tariff, 'plus')" style="cursor: pointer; user-select: all; pointer-events: all"/>
                          </div>
                          <p v-if="!activeCart[tariff.id]?.is_child" @click.stop="addToCart(tariff, 'child')">{{ $t("basket.addCard") }}</p>
                          <p v-else @click.stop="addToCart(tariff, 'child')">{{ $t("deletCart") }}</p>
                        </div>
                        <p v-if="tariff.action == 1" style="margin-top: 10px; color: #ED5336; text-align: center; font-weight: 700; font-size: 17px;">{{$t("bonusLoyality1")}}<span style="color: #44C45F;">{{$t("bonusLoyality2")}}</span></p>
                      </div>
                    </div>
                    <transition name="desc">
                    <div
                      v-if="index === openCard"
                      class="tariff-item__description"
                      v-html="tariff.large_description[$lang]"
                    ></div>
                  </transition>
                  </div>
                </div>
              </div>
            </div>
          </TabBlock>
          <TabBlock :isSelected="selected === $t('tabs.club')">
            <div class="tariffs__items">
              <div
                v-for="(tariff, index) in tariffs.club_card"
                class="tariff-item"
                :key="tariff.id"
                v-show="tariff.is_active == '1'"
              >
              <div class="tariff-item__content">
                  <div class="tariff-item__top" ref="tariff-top">
                    <div class="tariff-item__info" >
                      <div class="tariff-item__title" @click="openCard = (openCard === index ? -1 : index)">
                        <p>Тариф</p>
                        <img src="@/assets/icons/Icon.svg" style="cursor: pointer !important;"
                          data-show="true"/>
                      </div>
                      <div class="tariff-item__name" @click="openCard = (openCard === index ? -1 : index)">{{ tariff.ticket_title[$lang] }}
                        <img src="@/assets/icons/Icon.svg" style="cursor: pointer !important;"
                          data-show="true"/></div>
                          <p class="shortDesc">{{ tariff.short_description[$lang] }}</p>
                      <div class="tariff-item__adult">
                        <div class="tariff-item__adult__top">
                          <p class="name">{{ tariff.adult_ticket[$lang] }}</p>
                          <p class="price">{{ tariff.adult_actual_price }} тг.</p>
                        </div>
                        <div class="tariff-item__adult__top" style="align-items: end">
                          <p class="name1">{{ $t("priceWithout") }}</p>
                          <div style="border-bottom: 1px dotted; width: 100%;"></div>
                          <p class="price1">{{ tariff.adult_old_price }} тг.</p>
                        </div>
                        <template style="display: none;">
                          {{ tariff.counter = tariff.counter || 0 + activeCart[tariff.id]?.quantStat || 0 }}
                          {{ tariff.counterChild = tariff.counterChild || 0 + activeCart[tariff.id]?.quantChild || 0 }}
                        </template>
                        <div class="tariff-item__adult__count">
                          <div class="tariff-item__adult__count__left" :class="{disableCounter: activeCart[tariff.id]?.is_adult}">
                            <img src="@/assets/icons/minusCount.svg"  @click="updateCounter(tariff, 'minus')" style="cursor: pointer; user-select: all; pointer-events: all"/>
                            <p>{{tariff.counter ? tariff.counter : 0}}</p>
                            <img src="@/assets/icons/plusCount.svg" @click="updateCounter(tariff, 'plus')" style="cursor: pointer; user-select: all; pointer-events: all"/>
                          </div>
                          <p v-if="!activeCart[tariff.id]?.is_adult" @click.stop="addToCart(tariff, 'adult')">{{ $t("basket.addCard") }}</p>
                          <p v-else @click.stop="addToCart(tariff, 'adult')">{{ $t("deletCart") }}</p>
                        </div>
                      </div>
                      <div class="tariff-item__adult" v-if="tariff.child_actual_price">
                        <div class="tariff-item__adult__top">
                          <p class="name">{{ tariff.child_ticket[$lang] }}</p>
                          <p class="price">{{ tariff.child_actual_price }} тг.</p>
                        </div>
                        <div class="tariff-item__adult__top" style="align-items: end">
                          <p class="name1">{{ $t("priceWithout") }}</p>
                          <div style="border-bottom: 1px dotted; width: 100%;"></div>
                          <p class="price1">{{ tariff.child_old_price }} тг.</p>
                        </div>
                        <div class="tariff-item__adult__count">
                          <div class="tariff-item__adult__count__left" :class="{disableCounter: activeCart[tariff.id]?.is_child}">
                            <img src="@/assets/icons/minusCount.svg"  @click="updateCounterChild(tariff, 'minus')" style="cursor: pointer; user-select: all; pointer-events: all"/>
                            <p>{{tariff.counterChild ? tariff.counterChild : 0}}</p>
                            <img src="@/assets/icons/plusCount.svg" @click="updateCounterChild(tariff, 'plus')" style="cursor: pointer; user-select: all; pointer-events: all"/>
                          </div>
                          <p v-if="!activeCart[tariff.id]?.is_child" @click.stop="addToCart(tariff, 'child')">{{ $t("basket.addCard") }}</p>
                          <p v-else @click.stop="addToCart(tariff, 'child')">{{ $t("deletCart") }}</p>
                        </div>
                        <p v-if="tariff.action == 1" style="margin-top: 10px; color: #ED5336; text-align: center; font-weight: 700; font-size: 17px;">{{$t("bonusLoyality1")}}<span style="color: #44C45F;">{{$t("bonusLoyality2")}}</span></p>
                      </div>
                    </div>
                    <transition name="desc">
                    <div
                      v-if="index === openCard"
                      class="tariff-item__description"
                      v-html="tariff.large_description[$lang]"
                    ></div>
                  </transition>
                  </div>
                </div>
              </div>
            </div>
          </TabBlock>
        </div>
        <ModalComponent :showModal="showModal" @hideModal="hideModal">
          <div class="ticket-modalnew">
            <div class="ticket-modalnew__main">
              <div class="ticket-modalnew__main-button">
                <span
                  :class="{ active: activeCart[this.modalData.id] }"
                  @click.stop="addToCart(this.modalData)"
                >
                  <button
                    class="btn_out_blue"
                    v-if="!activeCart[this.modalData.id]"
                  >
                  {{ $t("basket.addCard") }}
                  </button>
                  <button class="btn_out_blue" v-else>Удалить с корзины</button>
                </span>
                <router-link
                  to="buy-ticket"
                  @click="openTariff(this.modalData.id)"
                  class="btn_blue"
                  >{{ $t("buyBilets") }}
                </router-link>
                <!-- <button class="blue-button">Оформить заказ</button> -->
              </div>
            </div>
          </div>
        </ModalComponent>
      </div>
    </div>
  </div>
</template>

<script>
import TabBlock from "@/components/TabBlock";
import AccordionItem from "@/components/AccordionItem";
import Accordion from "@/components/AccordionComponent";
import TabItem from "@/components/TabItem";
import axios from "axios";
import { mapGetters, mapMutations } from "vuex";
import { useToast } from "vue-toastification";
import ModalComponent from "@/components/ModalComponent";

export default {
  components: {
    Accordion,
    AccordionItem,
    TabBlock,
    TabItem,
    ModalComponent,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      isActive: "1",
      selected: this.$t("tabs.aquapark"),
      tariffs: [],
      isOpen: false,
      activeCart: [false],
      cartList: null,
      showModal: false,
      modalData: null,
      openCard: -1
    };
  },
  computed: {
    ...mapGetters({
      isAuth: "getIsAuth",
      isUnAuth: "getUnAuth",
    }),
  },
  methods: {
    updateCounter(item, operation){
      if (operation === 'plus') { 
        item.counter = item.counter ? item.counter + 1 : 1; 
      } else if (operation === 'minus') { 
          if (item.counter && item.counter > 0) { 
              item.counter--; 
          } 
      } 
    },
    updateCounterChild(item, operation){
      if (operation === 'plus') { 
        item.counterChild = item.counterChild ? item.counterChild + 1 : 1; 
      } else if (operation === 'minus') { 
          if (item.counterChild && item.counterChild > 0) { 
              item.counterChild--; 
          } 
      } 
    },
    ...mapMutations(["SET_TARIFFID"]),
    goToBuy() {
      if (this.isAuth) {
        this.$router.push("/buy-ticket");
      } else {
        this.$router.push("/login");
      }
    },
    changeText(ev) {
      ev.target.textContent === this.$t("more")
        ? (ev.target.innerText = this.$t("hide"))
        : (ev.target.innerText = this.$t("more"));
      // if (ev.getAttribute("data-show") === "true") {
      //   ev.innerText = this.$t("more");
      //   ev.setAttribute("data-show", "false");
      // } else {
      //   ev.innerText = this.$t("hide");
      //   ev.setAttribute("data-show", "true");
      // }
    },
    goToCart() {
      if (this.isAuth) {
        this.$router.push("/basket");
      } else {
        this.$router.push("login");
      }
    },
    selectTab(tab) {
      this.selected = tab;
    },
    openTariff(value, isOpen) {
      this.showModal = isOpen;
      localStorage.setItem("payment", "true");
      this.goToBuy();
      this.SET_TARIFFID(value);
    },
    openTariffOnCart(tariff) {
      localStorage.setItem("payment", "true");
      this.goToCart();
      this.SET_TARIFFID(tariff);
    },
    async addToCart(tariff, type = false) {
      if ((type === 'child' && tariff.counterChild === 0) || (type === 'adult' && tariff.counter === 0)) {
        this.toast.error(`Количество должно быть больше 0 `);
      } else {
        let product = tariff;
        product[`is_${type}`] = this.activeCart[product.id] && this.activeCart[product.id][`is_${type}`] ? false : true
        if (!tariff[`is_${type}`]) {
          tariff.counter = 0
          tariff.counterChild = 0
        }
        type === 'child' ? product[`quantChild`] = tariff.counterChild : type === 'adult' ? product[`quantStat`] = tariff.counter : ''
        console.log("product, product", product);
        // console.log('product[`is_${type}`]', product[`is_${type}`]);
        let cartList = JSON.parse(localStorage.getItem("cart_products"));
        if (cartList) {
          if (cartList.length >= 3) {
            if (cartList.find((tar) => tar.id === tariff.id)) {
              this.activeCart[tariff.id] = product;
              await this.$store.commit("ADD_TO_CART", { product, count: 1, type: type });
            } else {
              // alert("Вы можете выбрать в корзину максимум 3 тарифа");
              this.toast.warning(`Вы можете выбрать в корзину максимум 3 тарифа`);
            }
          } else {
            this.activeCart[tariff.id] = product;
            this.$store.commit("ADD_TO_CART", { product, count: 1, type: type });
          }
        } else {
          this.activeCart[tariff.id] = product;
          this.$store.commit("ADD_TO_CART", { product, count: 1, type: type });
        }
      }
    },
    inTheCart() {
      if (this.cartList) {
        this.cartList.filter((i) => {
          for (let x = 0; x < 4; x++) {
            let ids = this.tariffs[Object.keys(this.tariffs)[x]];
            ids.forEach((item, idx) => {
              if (i.id === item.id) {
                this.activeCart[item.id] = i;
              }
            });
          }
        });
      }
    },
    hideModal(isOpen) {
      this.showModal = isOpen;
    },
    openModal(tariff, isOpen) {
      // console.log('asdasdasd',tariff);
      this.modalData = tariff;
      this.showModal = !isOpen;
      document.body.style.overflow = "auto";
    },
  },
  created() {
    axios
      .get("https://admin.san-remo.kz/api/V1/ticket")
      .then((response) => ((this.tariffs = response.data), this.inTheCart()))

      .catch((error) => {
        // this.errorMessage = error.message;
        console.error("There was an error!", error);
      });
    this.cartList = JSON.parse(localStorage.getItem("cart_products"));
  },
  watch: {
    "this.showModal"() {
      if (this.showModal) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
  },
};
</script>

<style lang="scss">
.shortDesc{
  font-size: 20px;
  color: #626262;
  @media(max-width:768px){
    font-size: 13px;
  }
}
.btn_blue {
  font-weight: 700;
  // font-size: 20px;
  font-size: calc(15px + 5 * (100vw / 1320));
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
  background: #256ed8;
  border: 1px solid #256ed8;
  border-radius: 15px;
  padding: 5px 10px;
  width: 340px;

  @media (min-width: 991.9px) {
    padding: 18px 24px;
  }
}

.btn_out_blue {
  font-weight: 700;
  // font-size: 20px;
  font-size: calc(15px + 5 * (100vw / 1320));
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #256ed8;
  background: #ffffff;
  border: 1px solid #256ed8;
  border-radius: 15px;
  padding: 5px 10px;
  width: 340px;

  @media (min-width: 991.9px) {
    padding: 18px 24px;
  }
}
.customDesc{
  max-height: 2000px !important;
}
.ticket-modalnew {
  position: relative;
  width: 90%;

  &__main {
    &-button {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      gap: 10px;

      @media (max-width: 991.9px) {
        flex-direction: column;
      }

      span {
        button {
          max-width: 340px !important;
          min-width: 340px !important;
        }
      }

      a {
        max-width: 340px !important;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.loyalty-text {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  // font-size: 24px;
  line-height: 33px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #209e2f;
  margin-bottom: 32px;

  a {
    color: #ff6b6b;
    text-decoration: underline !important;
  }
}

.tariffs {
  padding: 65px 0;
  font-family: "Nunito";
  background: #44C45F;
  @media (max-width: 767.98px) {
    padding: 32px 0;
  }
  &__title {
    color: #339CB5;
    margin: 0 0 87.38px;

    &::after {
      background-image: url("@/assets/icons/title-icon.svg");
    }

    @media (max-width: 767.98px) {
      margin: 0 0 63px;
    }
  }

  &__items {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 50px;

    @media (max-width: 1366.98px) {
      gap: 40px;
    }

    @media (max-width: 767.98px) {
      justify-content: center;
      gap: 23px;
    }
  }
}
.name{
  font-size: 25px;
  color: #535353;
  width: 80%;
  font-weight: 700;
  @media(max-width:768px){
    font-size: 16px;
  }
}
.price{
  color: #44C45F;
  font-size: 25px;
  width: 25%;
  font-weight: 700;
  @media(max-width:768px){
      font-size: 14px;
    }
}
.name1{
  color: #00000080;
  font-size: 15px;
  font-weight: 700;
  min-width: max-content;
  @media(max-width:768px){
    font-size: 12px;
  }
}
.price1{
  color: #00000080;
  font-size: 15px;
  min-width: 25%;
  text-decoration: line-through;
  font-weight: 700;
  @media(max-width:768px){
        font-size: 12px;
      }
}
.tariff-item {
  flex: 0 1 47%;
  padding: 30px;
  letter-spacing: 0.02em;
  background: #ffffff;
  border: 2px solid #535353;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  &__description{
    margin-top: 30px !important;
  }
  &__title{
    @media(max-width:768px){
      display: none !important;
    }
  }
  &__adult{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-weight: 700;
    @media(max-width:768px){
      margin-top: 10px;
    }
    &__top{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__count{
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media(max-width:768px){
        flex-direction: column;
        gap: 20px;
        justify-content: space-evenly;
      }
      &__left{
        display: flex;
        gap: 15px;
        align-items: center;
        p{
          text-decoration: none !important;
          color: black !important;
        }
        img{
          width: 30px;
          height: 30px;
        }
      }
      p, a{
          font-size: 16px;
          color: #ED5336;
          text-decoration: underline !important;
          cursor: pointer;
          @media(max-width:768px){
            font-size: 13px;
          }
        }
    }
  }
  &__name{
    font-size: 30px;
    color: #44C45F;
    font-weight: 700;
    img{
      display: none;
    }
    @media(max-width:768px){
      font-size: 16px;
      display: flex;
      justify-content: center;
      gap: 5px;
      align-items: center;
      img{
        display: block;
        width: 20px;
        height: 20px;
      }
    }
  }
  &__info{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .accordion__item {
    display: flex;
    flex-direction: column-reverse;
  }

  @media (max-width: 1366.98px) {
    border-width: 2px;
    border-radius: 30px;
    padding: 20px 15px;
  }

  @media (max-width: 767.98px) {
    flex: 0 1 70%;
    padding: 24px 16px;
    border-width: 2px;
  }

  @media (max-width: 479.98px) {
    flex: 0 1 100%;
  }

  @media (min-width: 375px) {
    min-width: 343px;
  }

  :last-child {
    .tariffs-aquapark__price {
      margin: 0;
    }
  }
  &__content {
    flex: 1 0 auto;
  }

  .accordion {
    flex: 0 0 auto;
  }

  &__title {
    font-weight: 400;
    font-size: 18px;
    line-height: 138.888%;
    color: #a9a9a9;
    display: flex;
    justify-content: space-between;
    @media (max-width: 1366.98px) {
      font-size: 16px;
    }

    @media (max-width: 767.98px) {
      font-size: 14px;
    }
  }
}

.desc-enter-active { 
    animation: desc-in 0.9s; 
} 
   
.desc-leave-active { 
    animation: desc-in 0.4s reverse; 
} 
   
  @keyframes desc-in { 
    0% { 
      max-height: 0px; 
      opacity: 0;
    } 
   
    100% { 
      max-height: 2000px; 
      opacity: 1;
    } 
  }
  .disableCounter {
    pointer-events: none;
    opacity: 0.5;
    img, p {
      pointer-events: none !important;
    }
  }
</style>
